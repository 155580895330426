body {
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.name {
  display: block;
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.surname {
  display: block;
  color: black;
  height: 30px;
  font-weight: 500;
  font-size: 20px;
  color: #979a9a;
}

.add-count {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  border-radius: 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  padding: 0 10px;
  height: 25px;
  display: none;
}

.subtract-count {
  background-color: #f44336; /* Red */
  border: none;
  color: white;
  border-radius: 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  padding: 0 10px;
  height: 25px;
  display: none;
}

.pet {
  position: absolute;
  display: block;
  left: 135px;
  bottom: 60px;
  width: 100px;
  animation: MoveUpDown 10s linear infinite;
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
}

.petModal {
  position: absolute;
  display: block;
  bottom: 60px;
  width: 150px;
  animation: MoveUpDown 1s linear infinite;
}

.avatarContainer {
  position: relative;
}

.circle {
  border: 5px solid white;
  background-color: grey;
  height: 30px;
  border-radius: 50%;
  width: 30px;
  left: 35px;
  color: white;
  font-weight: 500;
  justify-content: right;
  position: relative;
  top: 250px;
  display: block;
  font-weight: bold;
  font-size: 20px;
}

.hidenCircle {
  border: 5px solid transparent;
  background-color: transparent;
  height: 30px;
  border-radius: 50%;
  width: 30px;
  left: 35px;
  color: transparent;
  font-weight: 500;
  justify-content: right;
  position: relative;
  top: 250px;
  display: block;
  font-weight: bold;
  font-size: 20px;
}

.circleGreen {
  border: 5px solid white;
  background-color: green;
  height: 30px;
  border-radius: 50%;
  width: 30px;
  left: 35px;
  color: white;
  font-weight: 500;
  justify-content: right;
  position: relative;
  top: 250px;
  display: block;
  font-weight: bold;
  font-size: 20px;
}

.totalCircle {
  border: 5px solid white;
  background-color: green;
  height: 30px;
  border-radius: 50%;
  width: 50px;
  left: 35px;
  color: white;
  justify-content: right;
  position: relative;
  top: 250px;
  display: block;
  font-weight: bold;
  font-size: 20px;
  padding-top: 5px;
}

.circleRed {
  border: 5px solid white;
  background-color: red;
  height: 30px;
  border-radius: 50%;
  width: 30px;
  color: white;
  font-weight: 500;
  justify-content: right;
  position: relative;
  top: 250px;
  left: 35px;
  display: block;
  font-weight: bold;
  font-size: 20px;
}

.simpleCircle {
  border: 5px solid white;
  height: 30px;
  border-radius: 20px;
  width: 40px;
  color: white;
  font-weight: 500;
  display: block;
  font-weight: bold;
  font-size: 20px;
  background-color: orange;
}

.counter {
  border: 5px solid;
  height: 30px;
  border-radius: 20px;
  width: 100px;
  color: white;
  font-weight: 500;
  display: block;
  font-weight: bold;
  font-size: 20px;
  background-color: orange;
  text-align: center;
}

/* Behaviour loops */
ul {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.avatarPointer {
  cursor: pointer;
}

.avatarImage {
  width: 350px;
  height: 300px;
}

.closeButton {
  display: inline-block;
  border-radius: 4px;
  background-color: #f4511e;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  width: 80px;
  cursor: pointer;
  margin-top: -20px !important;
  margin: 5px;
}

.cardDescriptionModal {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
  width: 200px;
  margin: 5px;
  font-size: 20px;
  line-height: 1.5;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  border-radius: 15px;
  border-color: rgb(131, 28, 28);
  border: 1px solid;
}

.cardDescriptionModal:hover {
  background-color: #f9f9f9;
  box-shadow: 0 0.2rem 0.5rem #aaa;
}

.imgAvatarRender {
  width: 250px;
}

/* Responsive mobile */
@media only screen and (max-width: 800px) {
  .avatarImage {
    display: none;
  }

  .petModal {
    display: none;
  }

  .cardDescriptionModal {
    padding: 2px;
    width: auto;
    margin: 2px;
    line-height: 1;
    font-size: 16px;
  }

  .simpleCircle {
    height: 20px;
    width: 30px;
    font-size: 15px;
  }

  .counter {
    display: none;
  }

  .closeButton {
    position: relative;
    bottom: 0px;
    left: -30px;
  }

  .petModal {
    left: 100px;
  }

  ul {
    justify-content: left;
  }

  .pet {
    left: 90px;
    width: 90px;
  }

  .imgAvatarRender {
    width: 150px !important;
  }

  .groupStudentsAvatar {
    display: none;
  }
}

/* Responsive small laptop screen */
@media only screen and (max-width: 1399px) and (min-width: 600px) {
  .closeButton {
    left: -30px !important;
    bottom: 0px !important;
  }

  .cardDescriptionModal {
    padding: 0px;
    width: auto;
    margin: 5px;
  }

  .counter {
    display: none;
  }

  .closeButton {
    position: relative;
    bottom: 500px;
    left: 50px;
  }

  .petModal {
    left: 150px;
  }

  ul {
    justify-content: left;
  }

  .pet {
    left: 110px;
    width: 90px;
  }

  .imgAvatarRender {
    width: 150px !important;
  }

  .circleGreen {
    top: 150px;
    left: 15px;
  }

  .totalCircle {
    top: 150px;
    left: 15px;
  }
}
.groupSelector {
  text-align: center;
  font-size: large;
  background-color: #009dd8;
  border-radius: 25px;
  padding: 5px;
  width: 10%;
  margin: auto;
  color: white;
}

/* Popup pet rewards */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup button {
  padding: 10px 20px;
}

.add-pet-popup-btn {
  font-size: larger;
  font-weight: bold;
  padding: 30% !important;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background: conic-gradient(
    #25b09b 25%,
    #f03355 0 50%,
    #514b82 0 75%,
    #ffa516 0
  );
  animation: l22 2s infinite linear;
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 15%;
  border-radius: 50%;
  background: inherit;
  animation: inherit;
}
.loader::after {
  margin: 25%;
  animation-duration: 3s;
}
@keyframes l22 {
  100% {
    transform: rotate(1turn);
  }
}

.studentGroupHeader {
  position: relative;
}

/* Halloween */
.halloween {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spider {
  position: absolute;
  top: 0;
  color: black;
  animation: animateSpider 15s ease-in-out infinite;
}
@keyframes animateSpider {
  0%,
  100% {
    transform: translateY(-500px);
  }
  50% {
    transform: translateY(0px);
  }
}
.pumpkin01 {
  position: absolute;
  top: 100px;
  right: 200px;
  animation: animatePumpkin 8s ease-in-out infinite;
  scale: 0.5;
}
.pumpkin02 {
  position: absolute;
  bottom: 20px;
  left: 50px;
  scale: 0.3;
  animation: animatePumpkin 4s ease-in-out infinite;
}

@keyframes animatePumpkin {
  0%,
  100% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(50px);
  }
}
.spiderWeb {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: screen;
}

@keyframes animate {
  0%,
  18%,
  20%,
  50.1%,
  60%,
  65.1%,
  80%,
  90.1%,
  92% {
    color: rgba(255, 255, 255, 0.1);
    text-shadow: none;
  }
  18.1%,
  20.1%,
  30%,
  50%,
  60.1%,
  65%,
  80.1%,
  90%,
  92.1%,
  100% {
    color: #ffac09;
    text-shadow: 0 0 10px #ffac09, 0 0 20px #ffac09, 0 0 40px #ffac09,
      0 0 80px #ffac09, 0 0 160px #ffac09;
  }
}

.spiderWeb {
  font-size: 10px;
}

/* Christmas theme */
#snowflake {
  width: 20px;
}

.snow {
  font-size: 20px;
  position: fixed;
  top: -5vh;
  transform: translateY(0);
  transform: rotate(180deg);
  animation: fall 7s linear forwards;
}

@keyframes fall {
  to {
    transform: translateY(105vh);
  }
}

.christmas_gift {
  position: absolute;
  top: 20;
  color: black;
  animation: animateSpider 15s ease-in-out infinite;
  width: 5%;
}
