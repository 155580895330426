/* Centrar el contenedor en la página */
.iframe-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/* Estilos para los iframes */
.iframe-style {
  margin: 20px; /* Espacio alrededor */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Sombra */
}
