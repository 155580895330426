.lootbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lootbox {
  width: 200px;
  height: 200px;
  transform-origin: center center;
  transition: filter 0.5s ease-in-out;
}

.lootbox.shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.lootbox.illuminate {
  filter: brightness(1.5) drop-shadow(0 0 10px rgba(255, 255, 255, 0.8));
}

.lootbox.final-shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.lootbox-content {
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #e74c3c;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.button-lootbox {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button-lootbox:hover {
  background-color: #c0392b;
}
