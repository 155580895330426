.form-container-admin {
  margin: 20px auto;
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.form-group-admin {
  margin-bottom: 15px;
}

.form-label-admin {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-input-admin,
.form-select-admin,
.form-button-admin {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-button-admin {
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

.form-button-admin:hover {
  background-color: #45a049;
}
