.randAvatar{
    display: flex;
    justify-content: center;
}

.avatarPointer{
    list-style-type: none;
}

.createRandomButton {
    cursor: pointer;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: Marvel;
    font-size: 14pt;
    width: 200px;
    height: 85px;
    border: solid #777 2px;
    color: white;
    z-index: 1;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
    overflow: hidden;
    background-color: rgb(11, 0, 112);
  }