.cookie-banner-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f2f2f2;
    padding: 10px;
    text-align: center;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .cookie-banner-text {
    margin: 0;
    flex-grow: 1;
  }
  
  .cookie-accept-button, .cookie-reject-button {
    padding: 5px 15px;
    margin: 0 5px;
    border: none;
    cursor: pointer;
  }
  
  .cookie-accept-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .cookie-reject-button {
    background-color: #FF5722;
    color: white;
  }
  