.createGroupsButton {
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: Marvel;
  font-size: 14pt;
  width: 200px;
  height: 75px;
  position: relative;
  border: solid #777 2px;
  color: white;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
  overflow: hidden;
  background-color: rgb(11, 0, 112);
}

.createGroupsButton:before {
  content: "";
  position: absolute;
  background-color: rgb(19, 153, 214);
  width: 110%;
  height: 300px;
  border-radius: 50%;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.createGroupsButton:hover:before {
  width: 0%;
  height: 0%;
}

.createGroupsButton:hover {
  color: #f1f1f1;
  border-color: rgb(11, 0, 112);
}

.createGroupsButton:active:before {
  background-color: #00a7fc;
}

.createGroupsButton:active {
  color: black;
  background-color: #00a7fc;
  border-color: #00a7fc;
}

.createGroupsButton:focus {
  outline: none
}

#result {
  display: flex;
  border-radius: 3px;
  margin-top: 10px;
  margin-left: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 500px;
  text-align:center;
  flex-wrap:wrap;
  align-content: flex-start;
  height: 100px;
  width:100%;
}

.avatarGroups ul{
  display:block;
  padding:20px;
}

.avatarGroups{
  padding:10px;
  margin:10px;
  box-shadow: 1px 1px 4px rgba(0,0,0,.2);
  background-color: #F8E57C;
  background-image: linear-gradient(to bottom right, #f8e072, #f5f593);
}

.avatarGroups:hover {
    right: 5px; top: 75%; bottom: 2%;
    width: 25%;
    box-shadow: -7px 4px 15px rgba(0,0,0,.5);
    transform: rotate(1deg);
}