.button-success {
    background: rgb(28, 184, 65);
    margin-right: 10px;
    width: 215px;
}
.button-success:hover {
    background: rgb(7, 138, 38);
}

.button-error {
    background: rgb(202, 60, 60);
    width: 215px;

}

.button-error:hover {
    background: rgb(241, 0, 0);
}

.button-warning {
    background: rgb(223, 117, 20);
    margin-right: 10px;
    width: 215px;

}

.button-secondary {
    background: rgb(66, 184, 221);
    margin-right: 10px;
    width: 215px;

}

.button-secondary:hover {
    background: rgb(1, 204, 255);
}


.settings {
    text-align: center;
    padding: 20px;
}

.settingsRules{
    padding:0;
}

.settings{
    display:flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.centerDivs{
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .settings{
      flex-flow:column;
    }
}