/* CountdownTimer.css */

.countdown-container {
    text-align: center;
    margin: 20px;
  }
  
  .countdown-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .countdown-inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .countdown-inputs span {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .countdown-inputs input {
    width: 40px;
    padding: 5px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #ccc;
  }
  
  .countdown-controls {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .countdown-controls button {
    font-size: 16px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
  }

  .countdown-inputs {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .countdown-inputs span {
    margin-bottom: 5px; 
  }
  
  .countdown-display {
    font-size: 70px; 
    font-weight: bold;
    margin-top: 20px;
    background-color: #007bff; 
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }

  .countdown-display-alert {
    font-size: 70px; 
    font-weight: bold;
    margin-top: 20px;
    background-color: #007bff; 
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    animation: blink 2s infinite;
  }

  @keyframes blink {
    0% {
      background-color: red;
    }
    50% {
      background-color: transparent;
    }
    100% {
      background-color: red;
    }
  }