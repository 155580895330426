.styled-table {
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;
  max-width: 900px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.btn {
  border: none;
  color: white;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  width: 150px !important;
}

.btn-x {
  border: none;
  color: white;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  width: 80px !important;
}

.btn-edit {
  background-color: #008cba;
}

.btn-delete {
  background-color: #f44336;
}

.btn-view {
  background-color: #e7e7e7;
  color: black;
}

.btn-success {
  background: #2ecc71;
  color: #ffffff;
}

.editTitle {
  text-align: center;
}
@media only screen and (max-width: 800px) {
  .styled-table th,
  .styled-table td {
    padding: 5px 5px;
  }
}
