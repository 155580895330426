html {
  background-color: #f5f5f3;
}

body {
  overflow-x: hidden;
}

.apps {
  width: 100%;
  margin: 10px auto;
  box-sizing: border-box;
}

.apps ul {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

/* Avatar size */
.apps ul li {
  display: inline-block;
  height: 225px;
  width: 225px;
  color: black;
  margin: 10px;
  text-align: center;
}

.apps ul li i {
  background: #eee;
  padding: 100px 15px;
  border-radius: 10px;
  margin-bottom: 3px;
  border: 5px solid #fff;
}

.apps ul li:hover i {
  border: 5px solid #ccc;
}

/* Tab navigation component */
.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  cursor: pointer;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

/* Mobile responsive Modals */
@media (max-width: 1400px) {
  .avatarImage {
    width: 250px !important;
    height: 250px !important;
  }

  .cardDescription {
    background-color: #fff;
    border-radius: 0px;
    padding: 0px !important;
    margin: 3px !important;
    font-size: 15px;
    line-height: 1;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
    border-radius: 0px;
    border-color: rgb(131, 28, 28);
    border: 1px solid;
  }
}

/* Avatar resize responsive */
@media (max-width: 1400px) {
  .apps ul li {
    display: inline-block;
    height: 150px;
    width: 150px;
    color: black;
    margin: 0px;
    padding: 20px;
    text-align: center;
  }
  .apps ul {
    margin-left: -70px;
  }
}

/* Newsletter */
.newsletter-container {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  position: relative; /* Para posicionar el botón de cerrar */
  max-width: 600px;
  margin: auto;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

.newsletter-container h2 {
  margin-bottom: 10px;
  color: #333;
}

.newsletter-description {
  color: #666;
  margin-bottom: 20px;
}

.newsletter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-field {
  width: 90%; /* El ancho del campo de texto en relación al contenedor */
  margin-bottom: 10px;
}

.form-field input[type="text"],
.form-field input[type="email"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-field.action {
  width: auto;
  margin-top: 20px; /* Espacio entre los campos de entrada y el botón */
}

.hidden-input {
  display: none;
}

.newsletter-submit {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.newsletter-submit:hover {
  background-color: #218838;
}
.hidden {
  display: none;
}

.thank-you-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.thank-you-container h1,
.thank-you-container h2 {
  color: #333;
  margin-bottom: 20px;
}

.thank-you-container p {
  color: #555;
  font-size: 16px;
  line-height: 1.6;
}
