.student-card {
  border: 1px solid #e0e0e0;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.student-info {
  flex-grow: 1;
  margin-left: 10px;
}

.student-name {
  font-weight: bold;
  display: block;
}

.student-surname {
  color: #555;
  display: block;
}

.popupMS {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1000;
}

.popupMS.open {
  display: block;
}

.popupMS button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #007bff; /* Color azul por defecto */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popupMS button:hover {
  background-color: #0056b3; /* Un azul más oscuro al pasar el ratón */
}

.popupMS button.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #dc3545; /* Color rojo */
}

.popupMS button.close:hover {
  background-color: #c82333; /* Un rojo más oscuro al pasar el ratón */
}

.popupMS button.points {
  background-color: #28a745; /* Color verde */
  margin-top: 10px; /* Espacio en la parte superior para separarlo de la lista */
}

.popupMS button.points:hover {
  background-color: #218838; /* Un verde más oscuro al pasar el ratón */
}

/* Responsive small laptop screen */
@media only screen and (max-width: 1300px) and (min-width: 600px) {
  .student-card {
    padding: 5px;
    margin-bottom: 5px;
  }
  .popupMS button.close {
    top: 15px;
    padding: 5px;
    width: 30%;
  }
  .popupMS button.points {
    margin-top: -20px;
  }
  .popupMS {
    padding: 0px;
  }
}

/* Hide checkox, make div selectable */
.hidden-checkbox {
  display: none; /* Oculta el checkbox, pero mantiene su funcionalidad */
}

.student-checkbox-label {
  display: flex;
  cursor: pointer;
  width: 100%; /* Asegura que el área de clic cubra todo el div */
}

.student-checkbox-label .student-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  width: 100%;
  transition: background-color 0.3s ease;
}

.student-checkbox-label .student-info:hover {
  background-color: #f1f1f1; /* Efecto al pasar el mouse */
}

/* Estilo para cuando el checkbox está seleccionado */
.hidden-checkbox:checked + .student-info {
  background-color: #d1e7dd; /* Cambia el color de fondo cuando está seleccionado */
  border-color: #0f5132; /* Cambia el color del borde */
}

.student-checkbox-label .student-info.selected {
  background-color: #d1e7dd; /* Color de fondo cuando está seleccionado */
  border-color: #0f5132; /* Color del borde cuando está seleccionado */
}
