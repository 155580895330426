/* StudentRecords.css */
.records-container {
    margin: 20px;
  }
  
  .record-item {
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .record-header {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filter-input {
    flex-grow: 1;
    min-width: 150px; /* Ancho mínimo para cada input */
    width: auto!important;
  }

  .records-link {
    display: inline-block;
    padding: 8px 8px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
  }
  
  .records-link:hover,
  .records-link:focus {
    background-color: #0056b3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .records-link:active {
    background-color: #004088;
  }