.chronoContainer {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 30px;
}
.clockC {
  position: absolute;
  margin-left: 100px;
}

.appChrono {
  background-color: #0e4d92;
  margin: 0 auto;
  width: 300px;
  height: 200px;
  position: relative;
  border-radius: 10px;
}

.pFont {
  font-size: 28px;
}

.stopwatch-card {
  position: absolute;
  text-align: center;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 325px;
  height: 130px;
  top: 110px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonChrono {
  outline: none;
  background: transparent;
  border: 1px solid blue;
  padding: 5px 10px;
  border-radius: 7px;
  color: blue;
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 150px;
  margin: 0 auto;
  margin-top: 5px;
}
