.footer-tools {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f9f9f9;
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilo del botón del footer */
.footer-button {
  background-color: #2196f3; /* Color de fondo del botón */
  color: white; /* Color del texto del botón */
  border: none; /* Quitamos el borde del botón */
  padding: 10px 20px; /* Añadimos espacio interno al botón */
  border-radius: 4px; /* Añadimos bordes redondeados */
  cursor: pointer; /* Cambiamos el cursor al pasar sobre el botón */
  transition: background-color 0.3s; /* Agregamos una transición al color de fondo */
  font-size: 14px; /* Tamaño de fuente */
}

.footer-button:hover {
  background-color: #0a7bcd; /* Cambiamos el color de fondo al pasar el cursor sobre el botón */
}

/* Estilo del texto dentro del botón */
.button-text {
  font-size: 14px; /* Tamaño de fuente del texto */
}

/* Mobile responsive */
@media screen and (max-width: 600px) {
  .footer-tools {
    display: none;
  }
}

.spacing {
  padding: 25px;
  margin: 25px;
}
