.sentenceButton {
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: Marvel;
  font-size: 14pt;
  width: 200px;
  height: 85px;
  position: relative;
  border: solid #777 2px;
  color: white;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
  overflow: hidden;
  background-color: rgb(11, 0, 112);
}

.sentenceButton:before {
  content: "";
  position: absolute;
  background-color: rgb(19, 153, 214);
  width: 110%;
  height: 300px;
  border-radius: 50%;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.sentenceButton:hover:before {
  width: 0%;
  height: 0%;
}

.sentenceButton:hover {
  color: #f1f1f1;
  border-color: rgb(11, 0, 112);
}

.sentenceButton:active:before {
  background-color: #00a7fc;
}

.sentenceButton:active {
  color: black;
  background-color: #00a7fc;
  border-color: #00a7fc;
}

.sentenceButton:focus {
  outline: none;
}

#fraseBonita {
  color: mediumseagreen;
  font-size: 55px;
  text-shadow: 0px 1px #fff;
}

.fraseBonitaContainer {
  margin-top: 25px;
  width: 100%;
  padding: 20px;
  background-color: white;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border: 1px solid #888;
  position: relative;
}

.fraseBonitaContainer:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: transparent #000000;
  border-width: 20px 20px 20px 0;
  top: 50%;
  left: -20px;
  margin-top: -20px;
}

.sentenceContainer {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .fraseBonitaContainer {
    position: absolute;
    width: 65%;
    margin-top: 300px;
  }

  #fraseBonita {
    font-size: 20px;
  }
}
