.containerFraseBonita {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  background: #dcb46d;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
  padding: 50px;
}

.containerPergamino {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: -50px;
}

#frasePergamino {
  color: #7a400c;
  font-size: 50px;
  text-shadow: 0px 2px black;
  text-align: center;
}
.enter-options {
  margin-top: 15px;
  margin-bottom: 15px;
}

.fraseBonita-container {
  margin-top: 25px;
}

@media only screen and (max-width: 1400px) {
  .containerFraseBonita {
    margin-top: 40px;
    width: 300px;
  }
  .pergaImg {
    width: 750px !important;
    margin-left: 220px;
  }

  #fraseBonita {
    font-size: 30px;
  }
}

.pergaButton {
  margin-top: 75px !important;
  height: 40px;
  width: 400px;
  position: relative;
  margin: 0;
  display: inline-block;
  background-repeat: repeat;
  padding: 0;
  font-family: Minecraft;
  text-align: center;
  color: white;

  box-sizing: border-box;
  font-size: 16px;
}

.pergaButton span {
  position: relative;
  line-height: 40px;
  bottom: 3px;
}

.pergaButton:hover {
  border-bottom: 4px solid #59639a;
  border-right: 2px solid #59639a;
  border-left: 2px solid #bdc6ff;
  border-top: 2px solid #bdc6ff;
  background-image: url("http://charliecowan.co.uk/mcbuttongenerator/button_active_center.png");
  color: #ffffa0;
}

.pergaImg {
  width: 1200px;
}
.pergamCloseBtn {
  margin-top: 0px;
  width: 10%;
  border-radius: 12px;
  margin-left: 10px;
  padding: 3px;
  background-color: red;
}

@media only screen and (max-width: 600px) {
  .containerFraseBonita {
    position: absolute;
    width: 65%;
    margin-top: 20px;
    margin-left: 10px;
  }

  .pergaButton {
    width: 70%;
    margin-left: 10px;
  }
  .pergamCloseBtn {
    margin-top: 0px;
    width: 30%;
    border-radius: 12px;
    margin-left: 10px;
    padding: 3px;
    background-color: red;
  }
  .pergaImg {
    display: none;
  }

  #frasePergamino {
    font-size: 40px;
  }

  .footer-pergam-btns {
    position: absolute;
    top: 70px;
  }
}
