.microphoneContainer {
    background-color:#222;
}
svg {
    display: block;
    width: 100%;
    height: 80%;
    padding: 0;
    margin: 0;
    position:absolute;
}
h6 {
    width: 100%;
    font-family: sans-serif;
    position:absolute;
    text-align:center;
    color:black;
}
.main-text {
    font-size: 40px;
    top: 40%;
}
.sub-text {
    font-size: 30px;
    font-weight: normal;
    top: 60%;
}
.sub-text span {
    font-size: 35px;
}
.button-container {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 85%;
}
button {
    padding: 13px 28px;
    width: 200px;
    text-align: center;
    font-size: 20px;
    border-radius: 12px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    outline: none;
    color: white;
    transition: background-color 200ms linear;
}
button span {
    display: block;
    float: left;
}
.green-button {
    background-color: #4CAF50;
}
.green-button:hover {
    background-color: #3e8e41;
}
.red-button {
    background-color: #db6247;
}
.red-button:hover {
    background-color: #cc5338;
}
path {
    stroke-linecap: square;
    stroke: white;
    stroke-width: 0.5px;
}

@media only screen and (max-width: 500px) {
    .main-text {
        font-size: 35px; 
    }
    .sub-text {
        font-size: 28px;
    }
    .sub-text span {
        font-size: 30px;
    }
    button {
        font-size: 18px;
    }
}

.stop0{
    stop-color:#db6247;
    stop-opacity:1
}
.stop40{
    stop-color:#f6e5d1;
    stop-opacity:1
}
.stop60{
    stop-color:#5c79c7;
    stop-opacity:1
}
.stop85{
    stop-color:#b758c0;
    stop-opacity:1
}
.stop100{
    stop-color:#222;stop-opacity:1
}