body {
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.rowHome {
  padding-left: 10%;
  padding-right: 10%;
  width: auto !important;
}

.column {
  flex: 50%;
}

.title {
  font-size: 60px;
  font-weight: 700;
  color: #2c2a50;
}

.titleH2 {
  font-size: 30px;
  font-weight: 700;
  color: #2c2a50;
}

.subtitle {
  font-size: 25px;
  font-weight: 700;
  color: #7a7a7a;
  line-height: 1.5;
}

.greenButton {
  background-color: #4caf50;
  color: white;
  border-radius: 20px;
  width: 25% !important;
  font-size: 800;
}

.upAndDown {
  animation: MoveUpDown 1s linear infinite;
  left: 0;
  bottom: 0;
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.cardDescription {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
  width: 200px;
  margin: 10px;
  font-size: 20px;
  line-height: 1.5;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  border-radius: 15px;
  border-color: rgb(131, 28, 28);
  border: 1px solid;
}

.cardDescription:hover {
  background-color: #f9f9f9;
  box-shadow: 0 0.2rem 0.5rem #aaa;
}

.container {
  display: flex;
}

.container-cards {
  display: flex;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.steps {
  border: 5px solid white;
  background-color: #2c2a50;
  height: 30px;
  border-radius: 50%;
  width: 30px;
  color: white;
  font-weight: 500;
  justify-content: right;
  position: relative;
  right: 0px;
  top: 0px;
  display: block;
  font-weight: bold;
  font-size: 20px;
}

.paddingHome {
  padding: 25px;
}

.imgHomeSelectors {
  width: 200px;
}

.cta {
  background-color: #7fdbff;
  height: 300px;
}

.chibi_playing {
  margin-top: 75px;
  margin-left: -10px;
  margin-bottom: -5px;
  overflow: hidden;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

blockquote {
  border-left: 10px solid#0073D9;
  color: #0073d9;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  font-size: 24px;
  font-weight: 700;
}

blockquote:before {
  color: #0073d9;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

@media only screen and (max-width: 850px) {
  #chibiClassroom {
    display: none !important;
  }

  #chibi_programmer {
    display: none !important;
  }

  #landing_lootboxes {
    display: none !important;
  }

  #chibiCustom {
    display: none !important;
  }

  .paddingHome {
    padding: 1px;
  }

  .cta {
    background-color: #7fdbff;
    height: 400px;
  }

  .imgHomeSelectors {
    width: 100px;
  }

  blockquote {
    margin: 1.5em -35px;
  }
  .container-cards {
    flex-wrap: wrap;
    margin: auto;
    width: 50%;
  }
  .chibi_playing {
    display: none !important;
  }
}

.classroom-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.classroom-card {
  flex: 1 0 200px;
  min-width: 200px;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.classroom-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.classroom-card h3 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
}

.classroom-card button {
  background-color: #008cba;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.classroom-card button:hover {
  background-color: #005f5f;
}
