body {
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.rowH {
  display: flex;
}

.columnH {
  flex: 50%;
  padding: 20px;
}

.title {
  font-size: 60px;
  font-weight: 700;
  color: #2c2a50;
}

.subtitle {
  font-size: 25px;
  font-weight: 700;
  color: #7a7a7a;
  line-height: 1.5;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.centerCH {
  margin: auto;
  width: 80% !important;
  text-align: center;
}
