header {
  width: 100%;
  height: 50px;
  background-color: #00aff0;
  text-align: center;
  position: relative;
  top: 0;
  overflow: hidden;
  z-index: 1000;
}

nav {
  position: absolute;
  bottom: 0;
  height: 60px;
  line-height: 60px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
nav a {
  color: white;
  font-weight: bold;
  display: inline-block;
  padding: 10px 15px;
  line-height: 1;
  text-decoration: none;
}
@media (max-width: 600px) {
  header {
    height: 60px;
  }
  nav {
    height: 65px;
    line-height: 0px;
  }
}
.cardDescription {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
  width: 200px;
  margin: 10px;
  font-size: 20px;
  line-height: 1.5;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  border-radius: 15px;
  border-color: rgb(131, 28, 28);
  border: 1px solid;
}

.cardDescription:hover {
  background-color: #f9f9f9;
  box-shadow: 0 0.2rem 0.5rem #aaa;
}

.exit-btn {
  background-color: transparent;
  margin-top: 15px;
  padding: 7px;
}

/* CSS */
.button-15 {
  background-image: linear-gradient(#42a1ec, #009dd8);
  border: 1px solid #0077cc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: "SF Pro Text", "SF Pro Icons", "AOS Icons", "Helvetica Neue",
    Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.022em;
  line-height: 1.47059;
  min-width: 30px;
  overflow: visible;
  padding: 4px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.centerBtn {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
}

.button-15:disabled {
  cursor: default;
  opacity: 0.3;
}

.button-15:hover {
  background-image: linear-gradient(#51a9ee, #147bcd);
  border-color: #1482d0;
  text-decoration: none;
}

.button-15:active {
  background-image: linear-gradient(#3d94d9, #0067b9);
  border-color: #006dbc;
  outline: none;
}

.button-15:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}

.button-15 a {
  color: white !important;
}
