#wrapper-todo {
  margin: 10px;
  padding-top: 100px;
  margin-top: 20px;
  text-align: center;
}

.li-todo {
  word-wrap: break-word;
  position: relative;
  right: 22px;
  list-style: none;
  padding: 5px;
  margin: 10px;
  font-family: "Open Sans";
  color: black;
  background-color: white;
  width: 100%;
  max-width: 701px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #16a085;
  color: #16a085;
}

#input-area {
  margin-bottom: 10px;
  position: relative;
  height: 46px;
  border: 2px;
  border: 2px solid #16a085;
  font-family: "open sans";
  font-size: 14px;
  color: #16a085;
  text-align: center;
  max-width: 600px;
  width: 100%;
}

#submit {
  background-color: white;
  display: inline-block;
  padding: 15px 30px;
  border: 2px solid #ddd;
  color: #16a085;
  text-decoration: underline;
  transition: all 0.6s;
  min-width: 100px;
  text-align: center;
  font-family: "Open Sans";
}

.submit-todo {
  border-color: #16a085 !important;
  color: #16a085 !important;
}
.submit-todo:hover {
  background: #16a085;
  color: #fff;
}

.checkbox-todo {
  margin-right: 10px;
  margin-left: 5px;
  margin-top: 5px;
  float: right;
  cursor: pointer;
}

#condition {
  top: 60px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-family: "open sans";
  color: #16a085;
  font-size: 20px;
}
