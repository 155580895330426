input[type="text"],
input[type="file"],
input[list],
input[type="number"],
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[list] {
  cursor: pointer;
}

input[type="submit"] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

.textAreaForm {
  height: 100px;
  width: 100%;
}

.button-add-pet {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #e98527;
  font-size: 15px;
}

.button-add-pet:hover {
  background-color: #b46318;
}

.warningRegister {
  background-color: #e98527;
  padding: 25px;
  margin: 25px;
  text-align: center;
  font-weight: 800;
  color: white;
  border-radius: 25px;
}

.hideClass {
  display: none;
}

.vertical-center {
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* Gallery responsive */
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.image-gallery > li {
  flex-basis: 250px; /* width: 350px; */
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .image-gallery {
    justify-content: flex-start;
  }
  .image-gallery > li {
    flex-basis: 150px; /* width: 350px; */
    position: relative;
    cursor: pointer;
  }
  .addClassroom-row {
    flex-direction: column !important;
  }
  /* .pulsingButton {
    margin: 0px auto;
  } */
  .h2StudentsArrayColumn {
    padding-top: 40px;
  }
}

.image-gallery li img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: 5px;
}

.image-gallery::after {
  content: "";
  flex-basis: 150px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(57, 57, 57, 0.502);
  top: 0;
  left: 0;
  transform: scale(0);
  transition: all 0.2s 0.1s ease-in-out;
  color: #fff;
  border-radius: 5px;
  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* hover */
.image-gallery li:hover .overlay {
  transform: scale(1);
}

.errorWarning {
  background-color: red;
  padding: 20px;
  color: white;
  font-size: large;
  border-radius: 10px;
}

.css-1nmdiq5-menu {
  text-align: center;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.classListState {
  flex-direction: column;
  text-align: center;
}

.emoji-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  margin-left: 10%;
}

.emoji-item {
  margin-right: 10px;
  margin-bottom: 10px;
}

.emoji {
  font-size: 30px; /* Ajusta el tamaño según tus necesidades */
  cursor: pointer;
}

/* Basic button styling */

.publishingClassBtn {
  width: 200px;
  text-align: center;
  white-space: nowrap;
  display: flex;
  padding: 10px;
  margin: auto;
  justify-content: center;
  box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  border-radius: 10px;
  background-color: #ff0000;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-size: 22px;
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  color: #ffffff;
  transition: all 300ms ease-in-out;
}

/* Animation */

@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

.business-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.business-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Cambiado de 'start' a 'center' */
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%; /* Asegura que ocupe todo el ancho del contenedor padre */
}

.business-item-render {
  flex: 0 0 calc(33.33% - 20px);
  padding: 10px;
  background-image: url("../../public/images/business/street_render.png");
  background-size: cover; /* Ajusta la imagen para cubrir todo el espacio */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-position: center; /* Centra la imagen */
  text-align: center;
  box-sizing: border-box;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.business-image {
  width: 150px;
  height: 150px;
  margin-bottom: -10px;
}

.business-avatar {
  width: 50px;
  height: 50px;
}


.business-name {
  font-weight: bold;
  background-color: white;
  border-radius: 5px;
  width:70%;
  margin-left: 40px;
}

.progress-container {
  width: 100%;
  height: 20px;
  background-color: #ccc;
  position: relative;
}

.progress-bar {
  height: 100%;
  background-color: #007bff;
  width: 0%;
  position: absolute;
  transition: width 0.5s ease-in-out;
  color: white;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .business-list {
    flex-direction: column;
  }
  .business-item {
    flex: 0 0 100%; /* Ajusta el ancho para apilamiento en pantallas pequeñas */
  }
  .business-item-render{
    height: auto;
  }
}