body{
    font-family: proxima-nova,'Helvetica Neue',Helvetica,Arial,sans-serif;
  }
  
  .row {
    display: flex;
  }
  
  .column {
    flex: 50%;
  }

  .container {
    display: flex;
  }

  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: none;
  }
  
  a:active {
    text-decoration: none;
  }

  @media only screen and (max-width: 1400px) {
    .toolsCircle{
      width: 180px!important;
    }
  }

  .toolsCircle{
    width: 250px;
    margin: 0px 25px 0px 25px;
    margin-top: 20px;
  }

  .textCaption{
    text-align: center;
    font-weight: 700;
    padding-top: -25px;
  }

  .toolsContainer {
    display: flex;
    flex-wrap:wrap;
    padding:25px;
    justify-content: center; /* center items vertically, in this case */
    align-items: center;     /* center items horizontally, in this case */
  }

  @media only screen and (max-width: 600px) {
    #petsForest{
        display:none;
    }
}